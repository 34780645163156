import axios from '@/services/axios';
import { IForm2Transport, IForm3Road } from '@/types';
import { AxiosResponse } from 'axios';

export class RoadFacilitiesForm3 {
  static getData({ municipalityId, date, transportId, timeFrom, timeTo }: {
    municipalityId?: number,
    date: string,
    transportId?: number,
    timeFrom?: string,
    timeTo?: string,
  }) {
    return axios.get('/report-road-machinery/form3', {
      params: {
        municipality_id: municipalityId,
        transport_id: transportId,
        time_from: timeFrom,
        time_to: timeTo,
        date,
      },
    })
      .then((response: AxiosResponse<{
        transports: Array<IForm2Transport>,
        transportRoads: Array<IForm3Road>,
      }>) => response.data);
  }
}
