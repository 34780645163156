import { render, staticRenderFns } from "./form3.vue?vue&type=template&id=2d92c810&scoped=true&"
import script from "./form3.vue?vue&type=script&lang=ts&"
export * from "./form3.vue?vue&type=script&lang=ts&"
import style0 from "./form3.vue?vue&type=style&index=0&id=2d92c810&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d92c810",
  null
  
)

export default component.exports