




















































































































































































/* eslint-disable camelcase */
import { computed, defineComponent, reactive, ref, watch } from '@vue/composition-api';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
import PrintFooter from '../../../components/print/print-footer.vue';
import PrintHeader from '../../../components/print/print-header.vue';
import DatePicker from 'vue2-datepicker';
import moment from 'moment';
import { ACL } from '../../../modules/acl/acl-service';
import { IForm2Transport, IForm3Road, ITransporter } from '../../../types';
import { ModelSelect } from 'vue-search-select';
import { Transporter } from '../../../services/api/transporter';
import { RoadFacilitiesForm3 } from '../../../services/api/report/road-facilities/form3';
import { ROAD_FACILITIES_TYPE_GROUP } from '../../../constants/RouteTypeGroups';
import { PROJECT_TITLE } from '@/constants/Global';

const municipalitiesHelpers = createNamespacedHelpers('municipalities');

export default defineComponent({
  name: 'road-facilities-form3',
  components: {
    PrintHeader,
    PrintFooter,
    DatePicker,
    ModelSelect,
  },
  setup(props, { root }) {
    const printing = ref(false);
    const currentDate = computed(() => moment(filter.date).format('DD.MM.YYYY'));
    const filteredTransporters = computed(() => ([
      {
        text: 'Название организации',
      },
      ...data.transporters.map(r => ({
        value: r.id,
        text: r.name,
      })),
    ]));
    const filteredTransports = computed(() => {
      if (!filter.transporter.value || !data.transporters.length) {
        return [];
      }

      const transporter = data.transporters.find(t => t.id === filter.transporter.value);

      if (transporter && transporter.transports) {
        return [
          {
            text: 'Транспортное средство',
          },
          ...transporter.transports.map(r => ({
            value: r.id,
            text: r.number,
          })),
        ];
      }
    });
    const { current } = municipalitiesHelpers.useGetters(['current']);
    const { setCurrentMunicipality } = municipalitiesHelpers.useActions(['setCurrentMunicipality']);
    const filter: {
      date: string | Date,
      timeFrom: string | Date,
      timeTo: string | Date,
      transporter: {
        value?: number,
        text?: string,
      },
      transport: {
        value?: number,
        text?: string,
      }
    } = reactive({
      date: '',
      timeFrom: '',
      timeTo: '',
      transporter: {},
      transport: {},
    });
    const canExport = ref(false);
    const canPrint = ref(false);
    const data: {
      value: Array<Partial<IForm2Transport>>,
      roads: Array<IForm3Road>,
      sum: Partial<IForm3Road>,
      transporters: Array<ITransporter>,
      popup: Array<{
        start_time: string,
        end_time: string,
      }>,
    } = reactive({
      value: [],
      transporters: [],
      roads: [],
      popup: [],
      sum: {},
    });
    const headers: {
      [key in keyof Omit<IForm2Transport, 'transportId' | 'route' | 'number' | 'trackerCrashes'>]: string
    } = {
      model: 'Марка, модель ТС',
      mileage: 'Фактический пробег, км',
      municipality: 'Место работы (название района, районов)',
      mileageOnTheLine: 'Фактический пробег на линии, км',
      machineHours: 'Машино-часы по факту',
      startAt: 'Время начала работы',
      endAt: 'Время окончания работы',
      stopsOnTheLineCount: 'Количество стоянок на линии более 5 минут',
      transportStorageLocation: 'Место хранения ТС',
    };

    const roadHeaders: {
      [key in keyof Omit<IForm3Road, 'id'>]: string
    } = {
      municipality: 'Место работы',
      road: 'Наименование дороги',
      distance: 'Пробег',
      startAt: 'Время начала работы',
      endAt: 'Время окончания работы',
      trackerCrashesCount: 'Сбои трекеров ГЛОНАСС',
      avgSpeed: 'Средняя скорость на линии, км/ч',
      speedViolationCount: 'Количество нарушений скоростного режима',
    };

    const sumRoadHeaders = Object.keys(roadHeaders).filter(k => k !== 'road' && k !== 'municipality');

    const notSharedColumns = ['municipality', 'mileageOnTheLine', 'machineHours', 'startAt', 'endAt'];

    const dateParseFormat = '<\\div \\s\\t\\y\\l\\e="\\f\\o\\n\\t-\\s\\i\\z\\e: 10\\p\\x; \\c\\o\\l\\o\\r: \\g\\r\\a\\y;">DD.MM.YY</\\d\\i\\v><\\d\\i\\v>HH:mm</\\d\\i\\v>';

    const groupedData = computed(() => data.value
      .reduce((acc, current) => {
        if (!current.transportId) {
          return acc;
        }

        if (!(acc as any)[current.transportId]) {
          (acc as any)[current.transportId] = [];
        }
        (acc[current.transportId] as any).push(current);

        return acc;
      }, {} as { [key: number]: Array<Partial<IForm2Transport>> }));

    const formatDate = (date: string | null) => {
      return date ? moment.utc(date).local().format(dateParseFormat) : '—';
    };

    if (Object.keys(root.$route.query).length) {
      const routeQuery = root.$route.query;

      const date = routeQuery.date as string;
      if (/\d{4}-\d{2}-\d{2}/.test(date)) {
        filter.date = moment(date).toDate();
      }

      const timeFrom = routeQuery.timeFrom as string;
      if (timeFrom && filter.date) {
        filter.timeFrom = moment(date + ' ' + timeFrom).toDate();
      }

      const timeTo = routeQuery.timeTo as string;
      if (timeTo && filter.date) {
        filter.timeTo = moment(date + ' ' + timeTo).toDate();
      }

      const municipalityId = routeQuery.municipalityId;
      if (municipalityId) {
        setCurrentMunicipality(Number(municipalityId));
      }

      const transporterId = routeQuery.transporterId;
      if (transporterId) {
        filter.transporter = {
          value: Number(transporterId),
        };
      }

      const transportId = routeQuery.transportId;
      if (transportId) {
        filter.transport = {
          value: Number(transportId),
        };
      }
    }

    const handleRoadForSum = (v: Array<Partial<IForm3Road>>, isFinal: boolean = false) => {
      const result = v.reduce((acc, transport) => {
        Object.keys(acc)
          .forEach((key) => {
            const element = transport[key as keyof IForm3Road];
            if (element) {
              switch (key) {
                case 'startAt':
                  if (!(acc as any)[key] || (acc as any)[key] === '—') {
                    (acc as any)[key] = element as string;
                  } else {
                    const oldTime = moment((acc as any)[key] as string, dateParseFormat);
                    const newTime = moment(element as string, dateParseFormat);

                    if (newTime.isBefore(oldTime)) {
                      (acc as any)[key] = newTime.format(dateParseFormat);
                    }
                  }
                  break;
                case 'endAt':
                  if (!(acc as any)[key] || (acc as any)[key] === '—') {
                    (acc as any)[key] = element as string;
                  } else {
                    const oldTime = moment((acc as any)[key] as string, dateParseFormat);
                    const newTime = moment(element as string, dateParseFormat);

                    if (newTime.isAfter(oldTime)) {
                      (acc as any)[key] = newTime.format(dateParseFormat);
                    }
                  }
                  break;
                case 'avgSpeed':
                  (acc as any)[key] = (acc as any)[key] ? Math.floor(((acc as any)[key] + Number(element)) / 2) : Number(element);
                  break;
                default:
                  (acc as any)[key] += Number(element);
                  break;
              }
            }
          });

        return acc;
      }, {
        distance: 0,
        startAt: '',
        endAt: '',
        trackerCrashesCount: 0,
        avgSpeed: 0,
        speedViolationCount: 0,
      });

      return result;
    };

    const getSum = (data: Array<Partial<IForm3Road>>) => {
      return handleRoadForSum(data);
    };

    const getData = () => {
      if (!filter.date || !filter.transporter.value || !filter.transport.value) {
        return;
      }

      const query = {
        municipalityId: current.value.id !== 0 ? current.value.id : undefined,
        transporterId: filter.transporter.value,
        transportId: filter.transport.value,
        date: moment(filter.date).format('YYYY-MM-DD'),
        timeFrom: filter.timeFrom ? moment(filter.timeFrom).format('HH:mm') : '',
        timeTo: filter.timeTo ? moment(filter.timeTo).format('HH:mm') : '',
      };

      if (root.$route.query.transporterId?.toString() !== query.transporterId?.toString() ||
        root.$route.query.transportId?.toString() !== query.transportId?.toString() ||
        root.$route.query.municipalityId?.toString() !== query.municipalityId?.toString() ||
        root.$route.query.timeFrom !== query.timeFrom ||
        root.$route.query.timeTo !== query.timeTo ||
        root.$route.query.date !== query.date) {
        root.$router.replace({
          query: query as any,
        }).catch(() => {});
      }

      RoadFacilitiesForm3.getData(query)
        .then((response: {
          transports: Array<IForm2Transport>,
          transportRoads: Array<IForm3Road>,
        }) => {
          data.value = [...response.transports.map(t => ({
            ...t,
            startAt: formatDate(t.startAt),
            endAt: formatDate(t.endAt),
            model: t.model ? t.model : '—',
            machineHours: Number(t.machineHours).toFixed(1),
          }))];

          data.roads = [...response.transportRoads.map(r => ({
            ...r,
            endAt: formatDate(r.endAt),
            startAt: formatDate(r.startAt),
          }))];

          data.sum = getSum(data.roads);

          canExport.value = ACL.can('report_export.form2');
          canPrint.value = true;
        });
    };

    const init = () => {
      Transporter.getTransporters({
        all: 1,
        filter: {
          municipality: current.value.id !== 0 ? current.value.id : undefined,
          typeGroup: ROAD_FACILITIES_TYPE_GROUP,
        },
        include: ['transports'],
      })
        .then((response: {
          transporters: Array<ITransporter>
        }) => {
          data.transporters = [...response.transporters];

          if (filter.transporter.value) {
            filter.transporter.text = data.transporters.find(t => Number(t.id) === Number(filter.transporter.value))?.name;
          }

          if (filter.transport.value) {
            filter.transport.text = data.transporters.find(t => Number(t.id) === Number(filter.transporter.value))?.transports
              ?.find(t => Number(t.id) === Number(filter.transport.value))?.number;
          }
        });

      getData();
    };
    init();

    const historyDateParams = computed(() => {
      const date = moment(filter.date);
      const dateFromObject = moment(date.startOf('day'));
      const dateToObject = moment(date.endOf('day'));
      let dateFrom = dateFromObject.format('YYYY-MM-DD HH:mm:ss');
      let dateTo = dateToObject.format('YYYY-MM-DD HH:mm:ss');

      if (filter.timeTo && filter.timeFrom) {
        const timeTo = moment(date.format('YYYY-MM-DD') + ' ' + moment(filter.timeTo).format('HH:mm:ss'));
        const timeFrom = moment(date.format('YYYY-MM-DD') + ' ' + moment(filter.timeFrom).format('HH:mm:ss'));

        if (timeFrom.isSameOrAfter(timeTo)) {
          dateToObject.add(1, 'days');
        }

        dateFrom = `${dateFromObject.format('YYYY-MM-DD')} ${timeFrom.format('HH:mm:ss')}`;
        dateTo = `${dateToObject.format('YYYY-MM-DD')} ${timeTo.format('HH:mm:ss')}`;
      } else if (filter.timeTo) {
        const timeTo = moment(filter.timeTo);
        dateTo = `${dateToObject.format('YYYY-MM-DD')} ${timeTo.format('HH:mm:ss')}`;
      } else if (filter.timeFrom) {
        const timeFrom = moment(filter.timeFrom);
        dateFrom = `${dateFromObject.format('YYYY-MM-DD')} ${timeFrom.format('HH:mm:ss')}`;
      }

      return {
        dateTo,
        dateFrom,
        dateToFormatted: moment(dateTo).format('DD.MM.YYYY HH:mm'),
        dateFromFormatted: moment(dateFrom).format('DD.MM.YYYY HH:mm'),
      };
    });

    watch(current, () => init());
    watch(filter, () => getData());

    return {
      groupedData,
      data,
      headers,
      roadHeaders,
      filter,
      printing,
      currentDate,
      current,
      canExport,
      canPrint,
      notSharedColumns,
      filteredTransporters,
      filteredTransports,
      sumRoadHeaders,
      historyDateParams,
      disabledAfter(date: string) {
        const newDate = moment(date);
        const maxDate = moment(new Date()).add(-1, 'days');

        return !newDate.isSameOrBefore(maxDate);
      },
      exportReport() {

      },
      printReport() {
        const header: HTMLElement | null = document.querySelector('header.header');
        const content: HTMLElement | null = document.querySelector('.content');
        const report: HTMLElement | null = document.querySelector('#print-report');

        if (!header || !content || !report) {
          return;
        }

        header.hidden = true;
        content.style.margin = '0';
        report.style.overflow = 'visible';
        printing.value = true;
        document.title = `Отчет Форма № 3 │ ${moment(filter.date).format('DD.MM.YYYY')}`;

        setTimeout(() => {
          window.print();

          header.hidden = false;
          content.style.margin = '';
          report.style.overflow = '';
          printing.value = false;
          document.title = PROJECT_TITLE;
        }, 1000);
      },
      getHistoryParams(transportId: number, transportRoadId?: number) {
        const transporterId = filter.transporter.value;
        const { dateTo, dateFrom } = historyDateParams.value;

        return {
          transporterId,
          transportId,
          transportRoadId,
          dateFrom,
          dateTo,
        };
      },
    };
  },
});
